import React from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "@igloonet-web/shared-ui/themes/igloonet"

import {
  Slider,
  TitleH2Center,
  PaddingHalfWrapper,
  Content,
  Link,
  ContactFormJobs,
} from "@igloonet-web/shared-ui"

import JobHeader from "../components/jobs/job-detail-header"

import ProfileKepi from "../images/team/profile/kepi.jpg"
import ProfileBara from "../images/team/profile/bara.jpg"
import ProfileWicki from "../images/team/profile/wicki.jpg"
import PositionStamp from "../components/position-stamp"
import Benefits from "../components/jobs/benefits"

const JobDetailMainWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  z-index: 1050;
`

const JobDetailWrapper = styled.div`
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  max-width: 944px;
  margin: 1.75rem auto;
  z-index: 2000;
  background: #ffffff;
`

const JobDetailWindow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`

const JobDetailLanguageVersion = styled(Link)`
  line-height: 1.5;
  display: block;
  text-align: right;
  padding-right: 2.5em;
  padding-top: 1em;
`

const JobDetailBody = styled.div`
  position: relative;
`

const JobDetailContent = styled.div`
  display: block;
  margin: auto;
  line-height: 1.5;
  max-width: 690px;
`

const LinuxAdminKaJobDetail = () => {
  const personSlider = [
    {
      id: 1,
      imageSrc: ProfileBara,
      name: "Bára Michalčíková",
      position: "administration",
    },
    {
      id: 2,
      imageSrc: ProfileKepi,
      name: "Kepi",
      position: "Head of Hosting and DevOps",
    },
    {
      id: 3,
      imageSrc: ProfileWicki,
      name: "Martin Taraba",
      position: "hosting support",
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <JobDetailWindow />

      <JobDetailMainWrapper>
        <JobDetailWrapper>
          <JobHeader />

          <JobDetailBody>
            <PositionStamp lang="en" />
            <JobDetailLanguageVersion to="/linux-admin-ka">
              přepnout na českou verzi
            </JobDetailLanguageVersion>
            <JobDetailContent className="px-2 px-lg-0">
              <PaddingHalfWrapper>
                <h1 className="d-block my-auto pb-5">Linux admin</h1>
                <p>
                  <strong>
                    Hi, we are <Link to="/">igloonet</Link>
                  </strong>{" "}
                  and we have been in hosting & devops business for eighteen
                  years now.
                </p>
                <p>
                  <strong>TL;DR:</strong> We are looking for a handy full-time
                  partner for small team in Brno. Someone who is responsible,
                  reliable, can handle anything, and can help us with both
                  routine admin worries and further development.
                </p>
                <p>
                  <strong>Note from the start:</strong> We choose our colleagues
                  carefully, but it&apos;s the attitude and character that
                  matters. We can build knowledge and experience together.
                </p>
              </PaddingHalfWrapper>

              <Content>
                <PaddingHalfWrapper>
                  <TitleH2Center>Why is this position important?</TitleH2Center>
                </PaddingHalfWrapper>
                <ul>
                  <li>
                    You&apos;re the reason people get{" "}
                    <a
                      href="https://www.snowboard-zezula.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      a new board
                    </a>
                    ,{" "}
                    <a
                      href="https://www.planetaher.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      a board game
                    </a>{" "}
                    for the weekend,{" "}
                    <a
                      href="https://www.doktorkladivo.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      a decent tool
                    </a>{" "}
                    or{" "}
                    <a
                      href="https://www.e-pneumatiky.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      tyres
                    </a>{" "}
                    for the summer.
                  </li>
                  <li>
                    Our customers can do a lot of cool stuff and we want them to
                    be able to focus on that. They won&apos;t have to worry
                    about the technical operation of their projects with you on
                    board.
                  </li>
                  <li>
                    All of us at igloo use a lot of internal systems that have
                    to work reliably.
                  </li>
                  <li>
                    Servers, virtuals, containers... they all need a lot of
                    love.
                  </li>
                </ul>
              </Content>

              <Content>
                <TitleH2Center>Is the job for you?</TitleH2Center>
                <p>
                  <strong>Your daily bread will be</strong>
                </p>
                <p>
                  <cite>
                    I&apos;d better clarify: this is a finish line, not
                    something you have to know when you start. But at the same
                    time, this is what you can look forward to encountering with
                    us. We actually use everything listed in day to day
                    operations.
                  </cite>
                </p>
                <ul>
                  <li>Ansible role playing and Terraforming.</li>
                  <li>Exploring and deploying new technologies.</li>
                  <li>
                    Programming and scripting in <em>Python</em>, <em>Bash</em>,{" "}
                    <em></em>AWK, <em>jq</em>…
                  </li>
                  <li>
                    Documenting practices and developing our internal{" "}
                    <em>Org-mode</em> wiki and help.
                  </li>
                  <li>
                    Configuring, testing and optimizing <em>Apache</em>,{" "}
                    <em>PHP</em>, <em>Nginx</em>, <em>MariaDB</em>,{" "}
                    <em>Redis</em>, <em>RabbitMQ</em>, <em>InnoDB cluster</em>…
                  </li>
                  <li>
                    Maintaining our private Cloud on <em>OpenNebula</em>,
                    debugging <em>Ceph</em>, <em>KVM</em>, <em>LXC</em>{" "}
                    containers...
                  </li>
                  <li>
                    Modifications and optimizations of internal systems (
                    <em>Icinga2</em>, <em>ELK</em>, <em>InfluxDB</em>,{" "}
                    <em>Grafana</em>, <em>Gitlab CI</em>…).
                  </li>
                  <li>Updates, updates and more updates.</li>
                  <li>Log tracking, troubleshooting.</li>
                </ul>
                <p>
                  <strong>What we care about</strong>
                </p>
                <ul>
                  <li>
                    <strong>You are reliable. No excuses.</strong>
                  </li>
                  <li>You know Linux well, ideally Debian.</li>
                  <li>
                    You can learn new things quickly. And you&apos;ll figure out
                    why even faster.
                  </li>
                  <li>
                    You can program. It&apos;s not about the language, it&apos;s
                    about the mindset. You know data types and how to work with
                    them.
                  </li>
                  <li>
                    You can solve a problem. Explore, analyze, write, solve, and
                    document. Not just the one you already know.
                  </li>
                  <li>
                    You can write documentation without problems. In a way that
                    others can understand. We don&apos;t have to push you.
                  </li>
                  <li>
                    You can debug problems. If something doesn&apos;t work, you
                    can quickly narrow down a potential problem, use the
                    appropriate tool, and get a solution or hotfix.
                  </li>
                  <li>
                    You are not stuck in the past. You have been improving your
                    knowledge steadily.
                  </li>
                  <li>
                    You know that security is important. You don&apos;t
                    compromise on it.
                  </li>
                  <li>
                    Czech language: it would be great if you know some, but
                    intermediate English is enough.
                  </li>
                </ul>
                <p>
                  <strong>
                    What we don&apos;t care about, on the other hand.
                  </strong>
                </p>
                <ul>
                  <li>
                    How old you are, whether you pee standing up or sitting
                    down, how you dress, where are you from, who you want to
                    live with or who your parents are.
                  </li>
                  <li>
                    Your education. It really doesn&apos;t matter to us. Not
                    your high school diploma, not your degrees, not your
                    certificates or diplomas (except for <i>BSc., SSc.</i> of
                    course).
                  </li>
                  <li>Lack of formal experience or glowing references.</li>
                  <li>
                    The fact that you meet everything in the previous paragraph.
                    It is enough if you trust yourself to master those with our
                    help in time.
                  </li>
                </ul>
                <p>
                  <strong>Our dreams</strong>
                </p>
                <ul>
                  <li>
                    You have a good relationship with Free Software. When you
                    find a bug, you report it and help with the solution.
                  </li>
                  <li>
                    You used to be busy programming in{" "}
                    <code>((Python|Ruby|Haskell|elisp|.+) )+</code>.
                  </li>
                  <li>
                    You can work with the customer to come up with a suitable
                    solution.
                  </li>
                  <li>
                    You know{" "}
                    <code>
                      ((L[AN]MP)|Redis|(Postgre|My)SQL|(Maria|Mongo)DB|rsyslog|Icinga|HAProxy|Xen|kvm|Docker)+
                    </code>
                    .
                  </li>
                  <li>
                    You are{" "}
                    <a
                      href="//github.com/emacs-evil/evil/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      evil
                    </a>{" "}
                    in (
                    <a href="//spacemacs.org/" target="_blank" rel="noreferrer">
                      spac
                    </a>
                    )?
                    <a
                      href="//gnu.org/software/emacs/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      emacs
                    </a>
                    .
                  </li>
                  <li>
                    You can&apos;t live without{" "}
                    <a href="//orgmode.org/" target="_blank" rel="noreferrer">
                      Org-mode
                    </a>{" "}
                  </li>
                  <li>
                    You enjoy sharing your knowledge, lecturing or{" "}
                    <Link to="/blog/">blogging</Link>.
                  </li>
                </ul>
              </Content>
              <PaddingHalfWrapper>
                <Content>
                  <PaddingHalfWrapper>
                    <TitleH2Center>What will you learn with us?</TitleH2Center>
                  </PaddingHalfWrapper>
                  <p>
                    There will be plenty, no matter how much you know now. We
                    stick to the tried and tested stuff, but we&apos;re always
                    looking for ways to improve. At the same time, we&apos;ve
                    got a lot of internal mini-projects underway to push
                    ourselves further.
                  </p>
                  <p>
                    Just a few things you&apos;ll probably come across in the
                    near future:
                  </p>
                  <ul>
                    <li>Automate server management via Ansible.</li>
                    <li>Create monitoring checks for Icinga.</li>
                    <li>
                      Work with spacemacs, Org-mode and the tools around it.
                    </li>
                    <li>
                      CEPH, KVM, OpenNebula, ZFS, Btrfs, GlusterFS, Open
                      vSwitch, InnoDB cluster, MongoDB, RabbitMQ, Redis and
                      Haproxy.
                    </li>
                    <li>
                      Lots of web hosting, domain and TLS certificate stuff.
                    </li>
                  </ul>
                </Content>
              </PaddingHalfWrapper>

              <PaddingHalfWrapper>
                <TitleH2Center>What&apos;s in store for you?</TitleH2Center>
                <p>
                  We&apos;re not looking for a superman with abnormal abilities.
                  We want to work with someone who enjoys Linux and will
                  gradually get into everything we do here. We want a lazy
                  person who automates everything.
                </p>
                <p>
                  We take care of three main areas: web hosting, virtual server
                  infrastructure, and customized solutions for us (hosting); our
                  developers, our marketeers; and our customers&apos; projects.
                </p>
                <p>
                  Most of us go to the office Monday through Wednesday and work
                  from home Thursday and Friday. If more remote work is better
                  for you, we can work it out. Working hours are by mutual
                  agreement. We have Debian on our servers, but you can have
                  whatever you want on your machine.
                </p>
                <p>
                  We&apos;ll gradually introduce you to our practices, teach you
                  parts you don&apos;t know and learn from you those we
                  don&apos;t know. And of course get right to work.
                </p>
                <p>
                  We operate in a very open way, the direction of our department
                  is devised by all of us together, as well as the way we work.
                  We use Redmine to track tasks and Kanban to organize work. We
                  like free software, we try to publish our customizations,
                  plugins and tools for others to use.
                </p>
                <p>
                  Even though server administration is a lot about routine, we
                  try to give everyone a variety of work and find enough space
                  to do the things they enjoy more.
                </p>
                <p>
                  There&apos;s a lot more to it, best to keep it to the
                  interview.
                </p>
              </PaddingHalfWrapper>

              <PaddingHalfWrapper>
                <TitleH2Center>Benefits</TitleH2Center>
                <Benefits />
              </PaddingHalfWrapper>

              <Slider
                title={"Who will you be working with?"}
                data={personSlider}
                style={{ minHeight: "28rem" }}
              />
            </JobDetailContent>
            <ContactFormJobs
              heading="Don't postpone it, write to Kepi"
              lang="en"
            />
          </JobDetailBody>
        </JobDetailWrapper>
      </JobDetailMainWrapper>
    </ThemeProvider>
  )
}

export default LinuxAdminKaJobDetail
